import { Route, Routes } from 'react-router-dom';
import {HomePage} from './Home/Home';
import {CompanyPage} from './Company/Company';
import {MediaPage} from './Media/Media';
import {ProductPage} from './Product/Product';
import {Bpage} from './Bpage/Bpage';
import {createContext,useContext,useRef} from 'react';
import {ALabContext,ALabContextContent} from './Context'
import { Updater, useImmer } from 'use-immer';
import { useEffect } from 'react';
import { Nav } from './Discs/Nav';
export const AppContext = createContext<ALabContextInterface>(undefined!)
const useHorizontalScroll = (context:ALabContext,setContext:Updater<ALabContext>,elementId: string) => {
    useEffect(() => {
        const element = document.getElementById(elementId);
        var touchMoving = false
        if (element) {
          var touchStart = 0
          var touchEnd = 0
          var pageSpeed = 0
          const onWheel = (e: { preventDefault: () => void; deltaY: number; }) => {
            e.preventDefault();
            if(e.deltaY > 0){
              setContext((newContext)=>{
                newContext.page += 1
                if(newContext.page > 13){
                  newContext.page = 13
                }
              })
              
            }else if(e.deltaY < 0){
              setContext((newContext)=>{
                newContext.page -= 1
                if(newContext.page < 0){
                  newContext.page = 0
                }
              })
            }
          };
          const onTouchStart = (event:TouchEvent)=>{
            touchMoving = true
            touchStart = event.touches[0].clientX
          }
          const onTouchMove = (event:TouchEvent)=>{
            touchEnd = event.touches[0].clientX
            const touchDelta = (touchEnd - touchStart) / document.body.clientWidth
            setContext((newContext)=>{
              newContext.currentPage -= touchDelta
            })
          }
          const onTouchEnd = (event:TouchEvent)=>{
            touchMoving = false
            const touchDelta = touchEnd - touchStart
            if(touchDelta < -100){
              setContext((newContext)=>{
                newContext.page += 1
                if(newContext.page > 13){
                  newContext.page = 13
                }
              })
            }else if(touchDelta > 100){
              setContext((newContext)=>{
                newContext.page -= 1
                if(newContext.page < 0){
                  newContext.page = 0
                }
              })
            }
          }
          element.addEventListener("touchstart", onTouchStart);
          element.addEventListener("touchmove", onTouchMove);
          element.addEventListener("touchend", onTouchEnd);
          element.addEventListener('wheel', onWheel, { passive: false });
          
          const scroll = ()=>{
            requestAnimationFrame(scroll);
            setContext((newContext)=>{
              var scrollPage = newContext.currentPage
              if(scrollPage<3){

              }else if (scrollPage<5){
                scrollPage = 3
              }else if (scrollPage<9){
                scrollPage -= 2
              }else if (scrollPage<10){
                scrollPage = 7
              }else if (scrollPage<11){
                scrollPage -= 3
              }
              else if (scrollPage<12){
                scrollPage = 8
              }else {
                 scrollPage -= 4 
              }
              element.scrollLeft = scrollPage * document.body.scrollWidth
              if(!touchMoving){
                const deltaPage = Math.abs(newContext.page - newContext.currentPage)
                pageSpeed = pageSpeed + 0.002
                pageSpeed = Math.min(pageSpeed,0.2,deltaPage*0.1)
                if(newContext.page > newContext.currentPage){
                  newContext.currentPage += pageSpeed
                }else{
                  newContext.currentPage -= pageSpeed
                }
              }
            })
          }
          scroll()
          return () => {
              element.removeEventListener('wheel', onWheel);
          };
        }
    }, [elementId]);
};

export interface ALabContextInterface{
  context:ALabContext;
  setContext:Updater<ALabContext>;
}

function App() {
  const [context,setContext] = useImmer(ALabContextContent())
  useHorizontalScroll(context,setContext,'myDiv');
  return (
    <AppContext.Provider value={{context,setContext}}>
      <div 
      id="myDiv" 
      style={{ 
        display: 'flex',
        flexDirection: 'row',
        background: "#282828",
        height: "100vh",
        overflow: "scroll",
        overflowY: "hidden",
        width: "100vw",
      }}>
         <style>
    {`
      #myDiv::-webkit-scrollbar { 
        display: none; /* Chrome, Safari and Opera */
      }
      #myDiv {
        scrollbar-width: none; /* Firefox */
        ms-overflow-style: none; /* Internet Explorer 10+ */
      }
      "--font-regular-url": ${process.env.PUBLIC_URL}/fonts/AlibabaPuHuiTi_2_55_Regular.woff2,
      "--font-bold-url": ${process.env.PUBLIC_URL}/fonts/AlibabaPuHuiTi_2_85_Bold.woff2,
    `}
  </style>
  <Nav  />
        <Bpage />
        <HomePage />
        <CompanyPage />
        <MediaPage />
        <ProductPage />
      </div>
    </AppContext.Provider>
  );
}

export default App;