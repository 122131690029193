import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./Bpage.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../App";
const Body = (props: {}) => {
  const humanRef = useRef<HTMLDivElement>(null);
  const textboxRef = useRef<HTMLDivElement>(null);
  const pRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const { context, setContext } = useContext(AppContext);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownmenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (
        dropdownRef.current &&
        dropdownmenuRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        dropdownmenuRef.current.style.display = "none";
        dropdownRef.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDropdown = () => {
    if (dropdownmenuRef.current && dropdownRef.current) {
      dropdownRef.current.style.backgroundColor = "rgba(0, 0, 0, 0.40)";
      dropdownmenuRef.current.style.display = "flex";
    }
  };
  
  const handleDropdownmenu = () => {
    
    setContext((newContext) => {
      newContext.page = 1;
    });
  }

  const handlejump = () => {
    window.location.href = "https://virtual.paeleap.com";
  };
  useEffect(() => {
    // console.log(context.currentPage);
    if (context.currentPage <= 0.1) {
      if (
        humanRef.current &&
        textboxRef.current &&
        pRef.current &&
        navRef.current
      ) {
        humanRef.current.style.opacity = "1";
        textboxRef.current.style.opacity = "1";
        pRef.current.style.clipPath =
          "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)";
        navRef.current.style.display = "flex";
      }
    } else {
      if (
        humanRef.current &&
        textboxRef.current &&
        pRef.current &&
        navRef.current
      ) {
        humanRef.current.style.opacity = "0";
        textboxRef.current.style.opacity = "0";
        pRef.current.style.clipPath =
          "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)";
        navRef.current.style.display = "none";
      }
      if (dropdownmenuRef.current && dropdownRef.current) {
        // console.log("!");
        dropdownRef.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
        dropdownmenuRef.current.style.display = "none";
      }
    }
  }, [context.currentPage]);
  return (
    <div className={styles.body}>
      <div className={styles.nav} ref={navRef}>
        <div>
          <img
            src="./images/白色长.png"
            style={{
              height: "2.8vw",
              marginLeft: "6vw",
            }}
            alt=""
          />
        </div>
        <div
          className={styles.dropdown}
          onClick={handleDropdown}
          ref={dropdownRef}
        >
          <div className={styles.box}>
            <p>Beta体验版</p>
            <img
              src="./images/vector.webp"
              style={{
                marginLeft: "0.6vw",
              }}
              alt=""
            />
          </div>
          <div className={styles.dropdownmenu} ref={dropdownmenuRef}>
            <a href="#" onClick={handleDropdownmenu}>
              Alpha正式版
            </a>
          </div>
        </div>
      </div>
      <div className={styles.banner} onClick={handlejump}>
        <div className={styles.human} ref={humanRef}>
          <img src="./images/虚拟人形象1.webp" alt="" />
        </div>
        <div className={styles.text_box} ref={textboxRef}>
          <p>点击和我互动吧~</p>
        </div>
        <div className={styles.logo_box}>
          <img src="./images/彩色竖版.webp " alt="" />
          <p ref={pRef}>
            Infuse the temperature of AI into life with technology
          </p>
        </div>
      </div>
    </div>
  );
};

export default Body;
export const Bpage = (props: {}) => {
  return (
    <div>
      <Body />
    </div>
  );
};
