import "./Nav.scss";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
import { useContext, useEffect, useRef } from "react";

export const Nav = (props: {}) => {
  const { context, setContext } = useContext(AppContext);
  const navRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleDropdown = () => {
    if (dropdownRef.current) dropdownRef.current.classList.toggle("open");
  };

  const handleOptionClick = () => {
    setContext((newContext) => {
      newContext.page = 0;
    });
  };

  useEffect(() => {
    if (context.page <= 0.9) {
      if (navRef.current) {
        navRef.current.style.display = "none";
      }
    } else {
      setTimeout(() => {
        if (navRef.current) {
          navRef.current.style.display = "flex";
        }
      }, 500);
    }
  }, [context.page]);
  return (
    <nav ref={navRef}>
      <div className="nav1">
        <img src="/images/白色长.png" alt="logo" className="logo-img" />
      </div>
      <div className="menu">
        <NavButton title={"首页"} beginIndex={1} endIndex={3} />
        <NavButton title={"公司介绍"} beginIndex={3} endIndex={9} />
        <NavButton title={"媒体宣传"} beginIndex={9} endIndex={11} />
        <NavButton title={"产品中心"} beginIndex={11} endIndex={14} />
      </div>
      <div className="nav2">
        <div className="dropdown" ref={dropdownRef} onClick={handleDropdown}>
          <div className="box">
            <Link to="">Alpha正式版</Link>
            <img src="./images/nav2.webp" alt="" />
          </div>
          <div className="dropdown-menu">
              <a href="#" onClick={() => handleOptionClick()}>Beta体验版</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavButton = (props: {
  title: string;
  beginIndex: number;
  endIndex: number;
}) => {
  const { context, setContext } = useContext(AppContext);
  const selected: boolean =
    context.page >= props.beginIndex && context.page < props.endIndex;
  return (
    <Link
      onClick={() => {
        setContext((newContext) => {
          newContext.page = props.beginIndex;
        });
      }}
      to={"/"}
      style={{
        opacity: selected ? "1" : "0.5",
        borderBottom: selected ? "solid 0.15625vw #238ef3" : "",
      }}
    >
      {props.title}
    </Link>
  );
};
