export interface ALabContext{
    page:number;
    currentPage:number;
}

export const ALabContextContent = ()=>{
    return {
        page:1,
        currentPage:1
    }
}