import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./Media.module.scss";
import { useNavigate } from "react-router-dom";
import { Nav } from "../Discs/Nav";
import { AppContext } from "../App";

const Body = (props: {}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const dang = useRef<HTMLParagraphElement>(null);
  const { context, setContext } = useContext(AppContext);
  useEffect(() => {
    // console.log(context.currentPage)
    if (context.currentPage >= 8.9999999993 && context.currentPage < 10.1) {

      if (dang.current && context.currentPage < 10 && context.currentPage >= 9) {
        dang.current.style.display = "block";
      }
      if (boxRef.current) {
        var leftString = (45-(context.currentPage - 9)*92).toString() + "vw";
        // console.log(leftString);
        if(context.currentPage >9.99999999 )
        {
          leftString = "-47vw";
        }
        boxRef.current.style.left = leftString;
      }
    } else {
      if (dang.current) {
        dang.current.style.display = "none";
      }
    }
  }, [context.currentPage]);

  return (
    <div className={styles.body}>
      <div ref={dang} className={styles.dang}></div>
      <div className={styles.page}>
        <p className={styles.p_1} style={{ left: "0vw", top: "1vw" }}>
          媒体宣传
        </p>
        <div className={styles.box} ref={boxRef}>
          <div className={styles.banner}>
            <div className={styles.row}>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>央媒关注！未来科技城优质创业项目火出圈！</p>
                </div>
                <div className={styles.date}>
                  <p>杭州未来科技城 2023-06-27</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news1.webp" alt="" />
                </div>
              </div>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>华数受邀参展深圳文博会</p>
                </div>
                <div className={styles.date}>
                  <p>华数传媒 2023-06-08</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news2.webp" alt="" />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>让家变得更「聪明」（经济新方位·衣食住行看转型）</p>
                </div>
                <div className={styles.date}>
                  <p>人民日报 2023-06-18</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news3.webp" alt="" />
                </div>
              </div>
              <div className={styles.news} style={{ top: "0vw" }}>
                <div className={styles.title}>
                  <div
                    style={{
                      width: "1vw",
                      height: "1vw",
                      marginRight: "0.8vw",
                    }}
                  >
                    <div className={styles.circle}></div>
                  </div>
                  <p>
                    灿若星辰 |
                    18级ITPer陈小雨与华数集团合作研发项目「智慧家庭数智管家AI机器人」亮相2023康博会
                  </p>
                </div>
                <div className={styles.date} style={{ top: "1.2vw" }}>
                  <p>创新与创业管理强化班ITP 2023-04-20</p>
                </div>
                <div className={styles.content} style={{ top: "2.6vw" }}>
                  <img src="./images/news4.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.banner}>
            <div className={styles.row}>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>
                    校企联合研发项目「智慧家庭数智管家AI机器人」于2023康博会展出
                  </p>
                </div>
                <div className={styles.date} style={{ top: "0.87vw" }}>
                  <p>浙大设计 2023-04-19</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news5.webp" alt="" />
                </div>
              </div>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>
                    云创计划丨「数智管家」首展康博会，探索下一代智能家居新入口！
                  </p>
                </div>
                <div className={styles.date} style={{ top: "0.87vw" }}>
                  <p>浙江大学计算机学院研究生 2023-04-19</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news6.webp" alt="" />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.news}>
                <div className={styles.title}>
                  <div className={styles.circle}></div>
                  <p>
                    浙大双创院公司与华数集团合作开发的AI机器人亮相2023浙江（国际）康养产业博览会
                  </p>
                </div>
                <div className={styles.date} style={{ top: "0.87vw" }}>
                  <p>浙江大学校友总部经济园 2023-04-18</p>
                </div>
                <div className={styles.content}>
                  <img src="./images/news7.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MediaPage = (props: {}) => {
  return (
    <div>
      {/* <Nav page={9} /> */}
      <Body />
    </div>
  );
};
